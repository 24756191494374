import * as React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"

const ServiceCard = styled.div`
  margin: 2rem 1.6rem;
  padding: 0;
  box-shadow: ${props => props.theme.boxShadow.primary};
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 3px;

  .gatsby-image-wrapper {
    width: 100%;
    min-height: 200px;
    max-width: 600px;
  }

  h5 {
    text-align: center;
    margin: 1rem 0 0;
  }

  p {
    padding: 1rem;
    margin: 0;
  }
  .btn {
    margin: 0 0 1rem;
  }
`

const ServiceInfoCard = ({ service }) => {
  const data = useStaticQuery(graphql`
    query ImageQuery {
      allFile(filter: { sourceInstanceName: { eq: "services-images" } }) {
        edges {
          node {
            relativePath
            childImageSharp {
              gatsbyImageData(
                width: 600
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  `)

  const imageData = data.allFile.edges.filter(
    img => img.node.relativePath === service.img
  )

  const image = getImage(imageData[0].node)

  let length = 300

  return (
    <ServiceCard>
      <GatsbyImage image={image} alt={service.name} />
      <h5>{service.name}</h5>
      <p>{service.intro.substring(0, length)}...</p>
      <Link to={`/services/${service.slug}`} className="btn">
        Learn More
      </Link>
    </ServiceCard>
  )
}

export default ServiceInfoCard
